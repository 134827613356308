interface IConfig {
    IMPERSONATION_URL: string;
    AUTH_TYPE: string;
    IDENTITY_RESPONSE_TYPE: string;
    SSO_URL: string;
    AUTH_URL: string;
    IDENTITY_CLIENT_ID: string;
    ACCOUNTS_GRAPHQL_CLIENT: string;
    OS_GRAPHQL_CLIENT: string;
    CONSOLE_APPS_METADATA_GRAPHQL_CLIENT: string;
    WM_ENV: string;
    DATADOG_API_KEY: string;
    DATADOG_APPLICATION_ID: string;
    DATADOG_SITE: string;
    DATADOG_PROXY_HOST: string;
    DEPLOYABLE_API_PRIVATE: string;
    GALLERY_URL: string;
    COS_URL: string;
    AUTH_SILENT_LOGIN_FEATURE: string;
    CDN_SERVER: string;
    API_GATEWAY: string;
    ONBOARDING_APP_URL: string;
    CONDITION_BUILDER_URL: string;
    NX_RECORDER_SERVER: string;
    ADMIN_CENTER: string;
    NX_WEB_CLIENT_APPS: string;
    ACTIONBOT_DIALOG_UI_URL: string;
    ACTIONBOT_AI_GUID: string;
    ACTIONBOT_PROCESSOR_URL: string;
    NX_EDITOR_SITE: string;
    XBUILDER_URL: string;
    XBUILDER_CLIENT_SCRIPT_URL: string;
}

const json: IConfig = window['walkmeConsoleConfig'];
if (json === undefined) {
    throw new Error('Unable to load config.js');
}
export const CONFIG: IConfig = new Proxy(json, {
    get: (target, name) => {
        // eslint-disable-next-line no-prototype-builtins
        if (target.hasOwnProperty(name)) {
            return target[name];
        }
    },
});
