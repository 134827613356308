import { FederationHost } from '@module-federation/enhanced/runtime';
let federationHost: FederationHost = null;

export const shellHostName = 'shell';

//Federation hos instance - holds info for federation
export const getHost = () => federationHost;

export const setHost = (host: FederationHost) => {
    if (federationHost) return federationHost;
    federationHost = host;
};
