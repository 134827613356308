import { init } from '@module-federation/enhanced/runtime';
import { CONFIG } from '@walkme-os/config';
import { setHost, shellHostName } from './services/federation-service';

(async () => {
    try {
        const response = await fetch(
            `${CONFIG.CONSOLE_APPS_METADATA_GRAPHQL_CLIENT?.replace('graphql', 'ext/api/v1')}/configuration/federation`
        );
        const res = await response.json();
        setHost(
            init({
                name: shellHostName,
                remotes: res?.data?.map((app) => ({
                    name: app?.federation?.remoteName,
                    entry: app?.federation?.remoteEntry,
                })),
            })
        );
    } catch (error) {
        console.error('Error initializing remote entries:', error);
    } finally {
        await import('./bootstrap');
    }
})();
